<template>
  <!-- BIO BODY -->
  <scroll-container>
    <Container>
      <div class="flex flex-col lg:flex-row gap-4 min-h-screen">
        <!-- Company contact details section -->
        <div
          class="w-full lg:w-3/12 px-4 py-3 flex flex-col items-start justify-start overflow-hidden"
        >
          <div class="w-full text-center mb-5 block md:hidden">
            {{ company.company_name }}
          </div>
          <div class="flex flex-col items-start max-w-full">
            <image-selector class="mb-8" v-model="company_logo_file_id">
              <template #upload="{ change }">
                <Skeleton class="block mb-5" width="100%" height="10rem" v-if="loading" />
                <img
                  v-if="!loading && companyLogo"
                  class="pb-5 md:pb-8 w-full md:max-w-[350px] md:max-h-[200px]"
                  :src="companyLogo"
                  alt="Company Logo"
                />
                <div class="flex" v-else-if="!loading">
                  <div
                    @click="change"
                    class="w-36 h-36 bg-cool-gray-100 border-2 cursor-pointer hover:border-blue-print transition-all rounded-md border-dashed border-cool-gray-500 flex items-center justify-center"
                  >
                    <font-awesome-icon :icon="['far', 'plus']" class="mr-2" />
                    Add logo
                  </div>
                  <info-tooltip class="ml-4 hidden md:block"> Add your company logo </info-tooltip>
                </div>
              </template>
            </image-selector>
            <div class="flex flex-col items-start ml-4 md:ml-0 max-w-full">
              <Skeleton class="block mb-2" width="15rem" height="2rem" v-if="loading" />
              <div v-if="!loading" class="mb-2 text-sm md:text-base flex items-center w-full">
                <font-awesome-icon class="mr-5" :icon="['far', 'globe-pointer']" />
                <div class="w-full">
                  <StringField
                    classes="w-full"
                    v-model="company_website"
                    placeholder="Enter website"
                  />
                </div>
                <info-tooltip class="ml-4 hidden md:block">
                  Confirm your company information
                </info-tooltip>
              </div>
              <Skeleton class="block mb-2" width="15rem" height="2rem" v-if="loading" />
              <div v-if="!loading" class="mb-2 text-sm md:text-base flex items-center w-full">
                <font-awesome-icon class="mr-5" :icon="['far', 'phone']" />
                <div class="w-full">
                  <StringField
                    classes="w-full"
                    v-model="company_phone"
                    placeholder="Enter phone number"
                  />
                </div>
              </div>
              <Skeleton class="block mb-2" width="15rem" height="2rem" v-if="loading" />
              <div v-if="!loading" class="mb-2 text-sm md:text-base flex items-center w-full">
                <font-awesome-icon class="mr-5" :icon="['far', 'envelope']" />
                <div class="w-full">
                  <StringField classes="w-full" v-model="company_email" placeholder="Enter email" />
                </div>
              </div>

              <Skeleton class="block mb-2" width="15rem" height="2rem" v-if="loading" />
              <div v-if="!loading" class="mb-2 text-sm md:text-base flex items-center mt-4 w-full">
                <font-awesome-icon class="mr-5" :icon="['far', 'location-dot']" />
                <div class="w-full">
                  <StringField
                    classes="w-full"
                    v-model="company_address"
                    placeholder="Enter address"
                  />
                </div>
              </div>
              <Skeleton class="block mb-2" width="15rem" height="2rem" v-if="loading" />
              <div v-if="!loading" class="mb-2 text-sm md:text-base flex items-center pl-5 w-full">
                <div class="ml-3 w-full">
                  <StringField classes="w-full" v-model="company_city" placeholder="Enter city" />
                </div>
              </div>
              <Skeleton class="block mb-2" width="15rem" height="2rem" v-if="loading" />
              <div
                v-if="!loading && oMeta"
                class="mb-2 text-sm md:text-base flex items-center mt-8 w-full"
              >
                <font-awesome-icon class="mr-5" :icon="['fab', 'facebook']" />
                <div class="w-full">
                  <StringField
                    classes="w-full"
                    :value="oMeta.facebook"
                    placeholder="Enter facebook url"
                    @blur="(val) => updateOMeta('facebook', val)"
                  />
                </div>
              </div>
              <Skeleton class="block mb-2" width="15rem" height="2rem" v-if="loading" />
              <div
                class="mb-2 text-sm md:text-base flex items-center w-full"
                v-if="!loading && oMeta"
              >
                <font-awesome-icon class="mr-5" :icon="['fab', 'instagram']" />
                <div class="w-full">
                  <StringField
                    classes="w-full"
                    :value="oMeta.instagram"
                    placeholder="Enter instagram url"
                    @blur="(val) => updateOMeta('instagram', val)"
                  />
                </div>
              </div>
              <Skeleton class="block mb-2" width="15rem" height="2rem" v-if="loading" />
              <div
                class="mb-2 text-sm md:text-base flex items-center w-full"
                v-if="!loading && oMeta"
              >
                <font-awesome-icon class="mr-5" :icon="['fab', 'linkedin']" />
                <div class="w-full">
                  <StringField
                    classes="w-full"
                    :value="oMeta.linkedin"
                    placeholder="Enter linkedin url"
                    @blur="(val) => updateOMeta('linkedin', val)"
                  />
                </div>
              </div>
              <Skeleton class="block mb-2" width="15rem" height="2rem" v-if="loading" />
              <div
                class="mb-2 text-sm md:text-base flex items-center justify-between w-full"
                v-if="oMeta && !loading"
              >
                <font-awesome-icon class="mr-5" :icon="['fab', 'x-twitter']" />
                <div class="w-full">
                  <StringField
                    classes="w-full"
                    :value="oMeta['x-twitter']"
                    placeholder="Enter x-twitter url"
                    @blur="(val) => updateOMeta('x-twitter', val)"
                  />
                </div>
              </div>
              <info-tooltip class="ml-4 hidden md:block"> Add social media links </info-tooltip>
            </div>
          </div>
        </div>

        <!-- Company Bio section -->
        <div
          class="w-full lg:w-7/12 px-4 py-8 flex flex-col justify-start border-t border-cool-gray-200"
        >
          <Skeleton class="block mb-5" width="100%" height="3rem" v-if="loading" />
          <div v-if="!loading" class="mb-4 flex items-center">
            <StringField
              classes="w-full text-2xl font-bold"
              v-model="company_name"
              placeholder="Enter company name"
            />
            <info-tooltip class="ml-4 hidden md:block"> Enter your company name </info-tooltip>
          </div>

          <TabView class="company-bio--tabs">
            <TabPanel :header="l('About us')">
              <div class="flex">
                <ClassicEditor class="w-full" v-model="company_bio" basic />
                <info-tooltip class="ml-4 hidden md:block">
                  Write a short paragraph to introduce your company.
                </info-tooltip>
              </div>
              <CardSection class="mt-8">
                <CardList>
                  <CardListField class="!overflow-x-scroll">
                    <span>
                      <span>Upload images to show case the best of your works </span>
                    </span>
                    <FileList
                      class="left ml-2"
                      :starting-folder="`company-${company_id}`"
                      :id-list="true"
                      v-model="presentation_file_ids"
                    />
                  </CardListField>
                </CardList>
                <template #info>
                  <info-tooltip class="ml-4 hidden md:block">
                    Upload images to showcase the quality of your work.
                  </info-tooltip>
                </template>
              </CardSection>

              <div v-if="loading" class="flex">
                <div class="flex flex-col pr-5">
                  <Skeleton class="block mb-5" width="4rem" height="4rem" v-if="loading" />
                  <Skeleton class="block mb-5" width="4rem" height="4rem" v-if="loading" />
                  <Skeleton class="block mb-5" width="4rem" height="4rem" v-if="loading" />
                </div>
                <Skeleton class="block mb-5" width="100%" height="20rem" v-if="loading" />
              </div>
              <Galleria
                v-if="!loading"
                class="mb-6 md:mb-10"
                :images="images"
                :responsiveOptions="responsiveOptions"
              />
            </TabPanel>
            <TabPanel :header="l('Get a quote')">
              <h2 class="mb-4 w-full">
                {{
                  l('To get started creating an estimate, please select the options provided below')
                }}
              </h2>
              <p class="mb-12 w-full">
                {{
                  l(
                    `Simply choose one of the options below that best match your needs, and we'll instantly generate a shoppable estimate. You'll be redirected to the estimate, where you can make upgrades, refine your selections, and submit a request for proposal to the contractor with just one click.`
                  )
                }}
              </p>
              <OpenQuotes
                v-if="company_id"
                :edit="true"
                :companyId="company_id"
                :leadFormId="lead_rotation_id"
              />
            </TabPanel>
            <TabPanel :header="l('Company rating')">
              <grid
                v-if="companyId"
                class="flex px-0! pt-2"
                ref="grid"
                type="contractorRating"
                :camelCase="true"
                :rightPadding="false"
                title="Ratings"
                :isMain="false"
                :filters="{ companyId }"
                :createNew="false"
                :showToolbar="false"
                :order="[['timeCreated', 'desc']]"
                :visible="['creatorId', 'overallRating', 'description']"
              >
                <template #filterPresetBefore>
                  <RatingSummary :creatorId="null" :ratingData="contractorRatingData" />
                </template>
              </grid>
            </TabPanel>
          </TabView>
          <CardSection class="mt-4">
            <CardList>
              <CardListField>
                <span>
                  <span>Company lead form</span>
                  <small> Embed a custom form into the lead form. </small>
                </span>
                <Choose
                  :createFunction="onCreateLeadRotation"
                  schema="lead_rotation:lead_rotation_name"
                  v-model="lead_rotation_id"
                />
              </CardListField>
            </CardList>
            <template #info>
              <info-tooltip class="ml-4 hidden md:block">
                Add or create a lead form and add it to your showcase
              </info-tooltip>
            </template>
          </CardSection>
          <div v-if="lead_rotation_id" class="p-12 border border-cool-gray-200 rounded-md">
            <div class="grid grid-cols-1 3xl:grid-cols-2 gap-4">
              <div class="flex flex-col p-4">
                <h2 class="mb-4">Got a question?</h2>
                <p>
                  Filling out the contact form below. One of our agents will get back to you as soon
                  as possible.
                </p>
              </div>
              <div>
                <LeadFormContent
                  v-if="form"
                  :aoFields="form.aoFields"
                  :aoFieldOptions="form.aoFieldOptions"
                  :formFields="defaultFormFields"
                  :oForm="form.oForm"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Marketing material -->
        <div class="w-full hidden lg:w-2/12 lg:flex flex-col gap-5 pt-10">
          <PanelCard
            title="Bolster Showcase is free to use for everyone"
            description="Save time & money on web development.  Give your customers a polished sales experience that leaves a lasting impression for free"
          >
            <template #footer>
              <Btn severity="light"> Learn more </Btn>
            </template>
          </PanelCard>

          <PanelCard
            title="Perfect your sales experience. Start generating leads in a few clicks"
            description="Easily publish your catalog for clients to create shop-able quotes, make selections, and request estimates to prequalify leads."
          >
            <template #footer>
              <Btn severity="light"> Learn more </Btn>
            </template>
          </PanelCard>
        </div>
      </div>
    </Container>
  </scroll-container>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import useTranslation from '@/components/composables/Translation'
import EntityComputedFields from '../composables/EntityFields/EntityComputedFields'
import FileList from '@/components/ui/FileList.vue'
import useForm from '@/components/forms/Form'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import RatingSummary from '@/components/ratings/RatingSummary.vue'
import Grid from '@/components/ui/Grid/Grid.vue'
import OpenQuotes from '@/components/openQuote/OpenQuotes.vue'
import LeadFormContent from '@/components/leads/LeadFormContent.vue'
import StringField from '@/components/ui/Calculator/StringField.vue'
import Galleria from '@/components/ui/Gallery/Galleria.vue'
import RouteEntityContext from '@/components/composables/RouteEntityContext'
import Skeleton from 'primevue/skeleton'
import ClassicEditor from '@/components/ui/DocumentEditor/ClassicEditor.vue'

const storeName = 'Company'
const type = 'company'

const store = useStore()
const { l } = useTranslation()

const { refId } = RouteEntityContext.useRouteEntityContext()
const {
  company_id,
  company_name,
  company_email,
  company_bio,
  company_logo_file_id,
  company_website,
  company_phone,
  company_city,
  company_address,
  lead_rotation_id,
  company_is_ratings_public,
  presentation_file_ids,
  oMeta
} = EntityComputedFields.useEntityComputedFields({
  refId,
  type,
  store: storeName
})

const contractorRatingData = ref({})
const company = computed(() => store.state.session.company)
const companyId = computed(() => company.value.company_id)

const responsiveOptions = ref([
  {
    breakpoint: '1300px',
    numVisible: 4
  },
  {
    breakpoint: '575px',
    numVisible: 1
  }
])

const companyLogo = computed(() =>
  company_logo_file_id.value
    ? `${import.meta.env.VITE_BASE_FILE_URL}file/view/${company_logo_file_id.value}`
    : null
)

const images = computed(
  () =>
    presentation_file_ids.value &&
    presentation_file_ids.value.map((fileId) => {
      return `${import.meta.env.VITE_BASE_FILE_URL}file/view/${fileId}`
    })
)

const { form, fetchLeadForm, defaultFormFields } = useForm()

const getContractorRatingData = async () => {
  const { payload } = await store.dispatch('ajax', {
    path: `/api/${import.meta.env.VITE_LEAD_FORM_KEY}/external/companyBio`,
    data: { company_id: companyId.value }
  })
  const { contractor_ratings } = payload
  contractorRatingData.value = {
    byOthers: contractor_ratings.items
  }
}

const updateOMeta = (field, val) => {
  oMeta.value = {
    ...oMeta.value,
    [field]: val
  }
}

const onCreateLeadRotation = () => {
  return store.dispatch('LeadRotation/goToCreatePage', {})
}

watch(
  lead_rotation_id,
  () => {
    if (!lead_rotation_id.value) return
    fetchLeadForm(lead_rotation_id.value)
  },
  { immediate: true }
)

watch(
  company_is_ratings_public,
  (isPublic) => {
    if (!isPublic) return
    getContractorRatingData()
  },
  { immediate: true }
)
</script>
